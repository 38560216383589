<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionSell">
      <div class="category">
        <div class="firstCategory">
          <span
            @click="changeCateTwo(null,null)"
            class="item"
            :class="activeCateId === null ? 'active' : ''"
          >全部</span>
          <span
            @click="changeCateTwo(item.classId,item.className)"
            class="item"
            :class="activeCateId === item.classId ? 'active' : ''"
            v-for="item in varietiesCateList"
            :key="item.classId"
          >{{ item.className }}</span>
        </div>
        <div v-if="isFirst">
          <div class="categoryList" v-for="(items,index) of twocategoryOptions" :key="index">
            <div class="categoryTitle">{{ items.className }}</div>
            <div class="categoryContent">
              <span
                @click="changeThreeCate(item.classId,item.className,items.className)"
                class="item"
                :class="activeCateId === item.classId ? 'active' : ''"
                v-for="item in items.children"
                :key="item.classId"
              >{{ item.className }}</span>
            </div>
          </div>
        </div>
        <div v-if="!isFirst">
            <div style="line-height:30px;color:#666;">当前位置：现货采购>{{firstClassName}}>{{twoClassName}}>{{threeClassName}}</div>
          <div class="categoryList" v-for="(items,index) of treecategoryOptions" :key="index">
            <div class="categoryTitle">{{ items.name }}</div>
            <div class="categoryContent">
              <span
                @click="changeCateTree(item,index)"
                class="item"
                :class="item.activeCateId ? 'active' : ''"
                v-for="item in items.content"
                :key="item.id"
              >{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sort">
        <div class="sortDefault">
          <span
            class="sortItem"
            :class="this.activeSort == 1 ? 'active' : ''"
            @click="sortDatas(1)"
          >默认</span>
        </div>
        <div class="sortList">
          <span
            class="sortItem"
            :class="this.activeSort == 2 ? 'active' : ''"
            @click="sortDatas(2)"
          >
            价格
            <i v-show="isDescPrice == 'ASC'" class="fa fa-long-arrow-up" aria-hidden="true"></i>
            <i v-show="isDescPrice == 'DESC'" class="fa fa-long-arrow-down" aria-hidden="true"></i>
          </span>
          <span
            class="sortItem"
            :class="this.activeSort == 3 ? 'active' : ''"
            @click="sortDatas(3)"
          >
            更新时间
            <i v-show="isDescTime == 'ASC'" class="fa fa-long-arrow-up" aria-hidden="true"></i>
            <i v-show="isDescTime == 'DESC'" class="fa fa-long-arrow-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div class="productionList">
        <el-table :data="resourceDatas" style="width: 100%" stripe>
          <el-table-column prop="className" label="商品分类"></el-table-column>
          <!-- <el-table-column prop="variety" label="品种"></el-table-column> -->
          <el-table-column prop="grade" label="商品属性">
            <template slot-scope="scope">
              <span>{{ scope.row.specificationsName|firstAttributeTransformate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="unsettledWeight" label="可供货重量">
            <template slot-scope="scope">
              <span>{{ scope.row.unsettledWeight ? scope.row.unsettledWeight : '--' }}{{ scope.row.unit }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="quantity" label="数量"></el-table-column> -->
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <span>{{ scope.row.price ? scope.row.price : '--' }}元/{{ scope.row.unit }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="putWarehousTime" label="预计入库时间">
            <template slot-scope="scope">
              <span>{{ scope.row.putWarehousTime | DateFormateDay }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="bargainingStatus" label="是否可议价">
            <template slot-scope="scope">
              <span>{{ scope.row.bargainingStatus | isholdFormate }}</span>
            </template>
          </el-table-column>
           <el-table-column prop="tradeWay" label="交易方式">
            <template slot-scope="scope">
              <span>{{ scope.row.tradeWay | filterBuyWay }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="hangCreateTime" label="创建时间">
            <template slot-scope="scope">
              <span>{{ scope.row.hangCreateTime | DateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="220">
            <template slot-scope="scope">
              <el-button
                @click="goProductionDetails(scope.row.hangResourcesId,scope.row.pickResourcesId)"
              >查看详情</el-button>
              <el-button type="danger" @click.native.prevent="showOrderDialog(scope.row)">供货</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 下单弹窗 -->
    <orderDialog ref="orderDialog" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import searchHeader from "../base/header/searchHeader";
import orderDialog from "./orderDialog";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      sort: [{ property: "hangCreateTime", direction: "DESC" }],
      activeSort: 1,
      isDescPrice: "DESC",
      isDescTime: "DESC",
      activeCateId: null,
      varietiesCateList: [],
      activeNameId: null,
      varietiesNameList: [],
      varietiesNameListBackup: [],
      activeGradeId: null,
      gradeList: [],
      activeWeightId: null,
      weightList: [
        { id: 0, weightMin: 0, weightMax: 1000 },
        { id: 1, weightMin: 1000, weightMax: 1500 },
        { id: 2, weightMin: 1500, weightMax: 2000 },
        { id: 3, weightMin: 2000, weightMax: null }
      ],
      searchForm: {
        variety: null,
        commodityName: null,
        weightMin: null,
        weightMax: null,
        grade: null
      },
      resourceDatas: [],
      categoryOptions: [],
      isFirst: true,
      twocategoryOptions: [],
      treecategoryOptions: [],
      spescInfo:[],
      className:'',
      firstClassName:'',// 第一级
      twoClassName:'',// 第二级
      threeClassName:'',// 第二级
    };
  },
  components: {
    searchHeader,
    orderDialog
  },
  computed: {
    ...mapGetters(["sessionInfoGetter", "userTypeGetter"])
  },
  mounted() {
    this.getDatas();
    // this.getQueryCommodity();
    this.getQueryVarietiesCate();
    // this.getQueryVarietiesName();
    // this.getQueryGrade();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getQueryCommodity() {
      http.postFront(protocolFwd.param_getCommodityCategory).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.deleteCategoryOptionsLast(value);
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    // 第一级
    getQueryVarietiesCate() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.varietiesCateList = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },

    getQueryVarietiesName() {
      protocolFwd.param_queryVarietiesName.param.type = 1;
      http.postFront(protocolFwd.param_queryVarietiesName).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.varietiesNameList = value;
          this.varietiesNameListBackup = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getQueryGrade() {
      http.postFront(protocolFwd.param_queryGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.gradeList = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 第二级
    changeCateTwo(classId,ClassName) {
      if(classId==null){
        this.className = null
        this.spescInfo = []
        this.activeCateId=null
        this.isFirst = true
        this.twocategoryOptions=[]
        this.getDatas()
        return
      }
       this.firstClassName = ClassName
      this.isFirst = true
      this.activeCateId = classId;
      protocolFwd.param_getChildClassById.param.classId = classId;
      http.getRes(protocolFwd.param_getChildClassById).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.twocategoryOptions = value;
          console.log(this.twocategoryOptions);
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 点击属性
    changeCateTree(value, index) {
      let that = this;
      for (let j = 0; j < that.treecategoryOptions[index].content.length; j++) {
        that.treecategoryOptions[index].content[j].activeCateId = false;
      }
      value.activeCateId = true;
      let contentArray = [];
      for (let i = 0; i < that.treecategoryOptions.length; i++) {
        for (let k = 0; k < that.treecategoryOptions[i].content.length; k++) {
          let objKey = that.treecategoryOptions[i];
          let contentObj = that.treecategoryOptions[i].content[k];
          if (contentObj.activeCateId && contentObj.value != "全部") {
            let obj = {};
            obj[objKey.name] = contentObj.value;
            contentArray.push(obj);
          }
        }
      }
      if(contentArray.length==0){
        this.spescInfo=[]
      }else{
        let reg = /\[\{+/g
        let reg1 = /\}\]+/g
        let reg2 = /\}+/g
        let reg3 = /\{+/g
        let reg4 = /\:+/g
        let newArray = JSON.stringify(contentArray).replace(reg,"");
        newArray = newArray.replace(reg1,"");
        newArray = newArray.replace(reg2,"");
        newArray = newArray.replace(reg3,"");
        newArray = newArray.replace(reg4,": ");
        this.spescInfo = newArray
      }
    
      this.getDatas();
    },
    // 属性
    changeThreeCate(classId,className,twoClassName) {
     this.className = classId
      this.getDatas();
      this.threeClassName = className
      this.twoClassName = twoClassName
      protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = classId;
      http.postFront(protocolFwd.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isFirst = false;
          let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate);

          for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
            let obj = { id: 0, value: "全部", activeCateId: true };
            for (let j = 0; j < goodsGroupCommonSpec[i].content.length; j++) {
              goodsGroupCommonSpec[i].content[j].activeCateId = false;
            }
            goodsGroupCommonSpec[i].content.unshift(obj);
          }
          this.treecategoryOptions = goodsGroupCommonSpec;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    changeCate(id) {
      if (id != null) {
        for (let i = 0; i < this.categoryOptions.length; i++) {
          if (id == this.categoryOptions[i].Value) {
            for (let j = 0; j < this.categoryOptions[i].children.length; j++) {
              this.categoryOptions[i].children[j].id = this.categoryOptions[
                i
              ].children[j].Code;
              this.categoryOptions[i].children[j].value = this.categoryOptions[
                i
              ].children[j].Value;
            }
            this.varietiesNameList = this.categoryOptions[i].children;
          }
        }
      } else {
        this.varietiesNameList = this.varietiesNameListBackup;
      }
      this.activeCateId = id;
      this.searchForm.variety = this.activeCateId;
      this.getDatas();
    },
    changeName(id, name) {
      this.activeNameId = id;
      this.searchForm.commodityName = name;
      this.getDatas();
    },
    changeWeight(id, min, max) {
      this.activeWeightId = id;
      this.searchForm.weightMin = min;
      this.searchForm.weightMax = max;
      this.getDatas();
    },
    changeGrade(id) {
      this.activeGradeId = id;
      this.searchForm.grade = this.activeGradeId;
      this.getDatas();
    },
    sortDatas(sort) {
      switch (sort) {
        case 1:
          this.activeSort = sort;
          this.sort = [
             {
                property: "hangCreateTime",
                direction: "DESC",
              }
          ];
          this.getDatas();
          break;
        case 2:
          if (this.activeSort == sort) {
            this.sort = [
              {
                property: "price",
                direction: (this.isDescPrice =
                  this.isDescPrice == "DESC" ? "ASC" : "DESC")
              }
            ];
            this.getDatas();
          } else {
            this.activeSort = sort;
            this.sort = [
              {
                property: "price",
                direction: this.isDescPrice
              }
            ];
            this.getDatas();
          }
          break;
        case 3:
          if (this.activeSort == sort) {
            this.sort = [
              {
                property: "hangUpdateTime",
                direction: (this.isDescTime =
                  this.isDescTime == "DESC" ? "ASC" : "DESC")
              }
            ];
            this.getDatas();
          } else {
            this.activeSort = sort;
            this.sort = [
              {
                property: "hangUpdateTime",
                direction: this.isDescTime
              }
            ];
            this.getDatas();
          }
          break;
        default:
          break;
      }
    },
    getDatas() {
      protocolFwd.param_queryFirmOrderBeforeConfirmA.param = {
        page: this.current - 1,
        size: this.pageSize,
        hangResourceType: ["Q"],
        auditStatus: ['T'],
        hangResourcesStatus: ["O", "B", "A"],
        sort: this.sort,
        sourceId: "W",
        specs: this.spescInfo.length!=0?this.spescInfo:null,
        commodityId:this.className,
      };
      http.postFront(protocolFwd.param_queryFirmOrderBeforeConfirmA).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.resourceDatas = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    showOrderDialog(row) {
      if (this.sessionInfoGetter.isLogin == "true") {
        if (this.sessionInfoGetter.firmId == row.hangFirmId) {
          this.$EL_MESSAGE("不允许自买自卖");
          return;
        }
        this.$refs.orderDialog.showDialog(row);
      } else {
        this.$EL_MESSAGE("请先登录");
        this.$router.push("/login");
      }
    },
    TableRowClick(row, column, cell, event) {
      if (!column.label) {
        return;
      }
      this.$router.push({
        name: "purchaseDocumentDetails",
        params: {
          id: row.hangResourcesId,
          pickResourcesId: row.pickResourcesId
        }
      });
      event.stopPropagation();
    },
    goProductionDetails(id, pickResourcesId) {
      this.$router.push({
        name: "purchaseDocumentDetails",
        params: {
          id: id,
          pickResourcesId: pickResourcesId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.productionSell {
  padding: 15px;
  // width: 80%;
  // min-width: 1300px;
  width: 1200px;
  margin: 10px auto;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.productionSell .category {
  color: #333333;
}
.productionSell .category .item.active {
  color: #ffffff;
  background-color: $commonThemeColor;
}
.productionSell .categoryList {
  line-height: 45px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
}
.productionSell .category .categoryTitle {
  float: left;
  width: 75px;
  font-weight: 700;
}
.productionSell .category .categoryContent {
  margin-left: 45px;
}
.productionSell .firstCategory .item {
  margin-left: 1px;
  width: 10%;
  height: 50px;
  text-align: center;
  padding: 0px 5px;
  line-height: 50px;
  cursor: pointer;
  background-color: #dcdcdc;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.productionSell .category .categoryContent .item {
  margin: 0 5px;
  padding: 0 5px;
  cursor: pointer;
}
.productionSell .category .categoryContent .item.active {
  color: #ffffff;
  background-color: $commonThemeColor;
}
.productionSell .sort {
  height: 50px;
  line-height: 50px;
  color: #333333;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
}
.productionSell .sort .sortDefault {
  float: left;
  width: 45px;
  font-weight: 700;
  cursor: pointer;
}
.productionSell .sort .sortList {
  margin-left: 45px;
}
.productionSell .sort .sortList .sortItem {
  padding: 0 10px;
  cursor: pointer;
}
.productionSell .sort .sortItem.active {
  color: $commonThemeColor;
}
.productionSell .productionList .item {
  padding: 10px 0;
  line-height: 27px;
  border-bottom: 1px dashed #f2f2f2;
}
.productionSell .productionList .item .flexCol {
  display: flex;
}
.productionSell .productionList .firstCloumn {
  display: flex;
  overflow: hidden;
}
.productionSell .productionList .firstCloumn .imgBox {
  float: left;
  width: 180px;
  height: 130px;
  margin-right: 15px;
  background-color: #cccccc;
}
.productionSell .productionList .firstCloumn .imgBox p {
  line-height: 130px;
  text-align: center;
  color: #ffffff;
}
.productionSell .productionList .firstCloumn .contentBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.productionSell .productionList .secondCloumn,
.productionSell .productionList .thirdCloumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productionSell .productionList .fourthCloumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productionSell .productionList .cloumnLine {
  margin: 0 5px;
  color: #dcdcdc;
}
.productionSell .productionList .item .zjPrice {
  color: $commonThemeColor;
  font-weight: 700;
}
.productionSell .productionList .enterpriseName {
  margin-right: 10px;
}
.productionSell .pagination {
  margin-top: 10px;
}
</style>
